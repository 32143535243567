/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { Layout, Button, Row, Col } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import MenueItems from './MenueItems';
// import TopMenu from './TopMenu';
import { Div, SmallScreenAuthInfo, SmallScreenSearch, TopMenuSearch } from './style';
import HeaderSearch from '../components/header-search/header-search';
import AuthInfo from '../components/utilities/auth-info/info';

const { darkTheme } = require('../config/theme/themeVariables');

const { Header, Footer, Sider, Content } = Layout;
// const { darkMode } = config;

const ThemeLayout = (WrappedComponent) => {
  class LayoutComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        collapsed: false,
        hide: true,
        searchHide: true,
        activeSearch: false,
        size: true,
        showScrollUp: false,
        showScrollDown: true,
      };
      this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
      window.addEventListener("scroll", this.handleScroll);
      window.addEventListener("resize", this.updateDimensions);
      this.updateDimensions();
      this.handleScroll();
    }

    componentWillUnmount() {
      window.removeEventListener("scroll", this.handleScroll);
      window.removeEventListener("resize", this.updateDimensions);
    }

    scrollUp = () => {
      const currentPosition = window.scrollY;
      if (currentPosition > 0) {
        const scrollStep = Math.max(1, Math.ceil(currentPosition / 20));
        window.scrollTo(0, currentPosition - scrollStep);
        requestAnimationFrame(this.scrollUp);
      }
    };

    scrollDown = () => {
      const documentHeight = document.documentElement.scrollHeight;
      const windowHeight = window.innerHeight;
      const currentPosition = window.scrollY;

      if (currentPosition + windowHeight < documentHeight) {
        const scrollStep = Math.max(1, Math.ceil((documentHeight - currentPosition) / 20));
        window.scrollTo(0, currentPosition + scrollStep);
        requestAnimationFrame(this.scrollDown);
      }
    };

    handleScroll = () => {
      const documentHeight = document.documentElement.scrollHeight;
      const windowHeight = window.innerHeight;
      const currentPosition = window.scrollY;
      if (currentPosition <= 0) {
        this.setState({ showScrollUp: false, showScrollDown: true });
      }
      else if (currentPosition + windowHeight >= documentHeight) {
        this.setState({ showScrollUp: true, showScrollDown: false });
      }
      else {
        this.setState({ showScrollUp: true, showScrollDown: false });
      }
    };

    updateDimensions() {
      this.setState({
        collapsed: false,
      });
    }

    render() {
      const { collapsed, hide, searchHide, activeSearch, size, showScrollUp, showScrollDown } = this.state;
      const { ChangeLayoutMode, rtl, topMenu } = this.props;

      const left = !rtl ? 'left' : 'right';
      const darkMode = ChangeLayoutMode;
      const toggleCollapsed = () => {
        this.setState({
          collapsed: !collapsed,
        });
      };

      const toggleCollapsedMobile = () => {
        if (window.innerWidth <= 990) {
          this.setState({
            collapsed: !collapsed,
          });
        }
      };

      const onShowHide = () => {
        this.setState({
          hide: !hide,
          searchHide: true,
        });
      };

      const screenSize = () => {
        this.setState({
          size: !size,
        });
      }

      const toggleSearch = () => {
        this.setState({
          activeSearch: !activeSearch,
        });
      };

      // const handleSearchHide = (e) => {
      //   e.preventDefault();
      //   this.setState({
      //     searchHide: !searchHide,
      //     hide: true,
      //   });
      // };

      const footerStyle = {
        padding: '20px 30px 18px',
        color: 'rgba(0, 0, 0, 0.65)',
        fontSize: '14px',
        background: 'rgba(255, 255, 255, .90)',
        width: '100%',
        boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
      };

      const SideBarStyle = {
        margin: size ? '63px 0 0 0' : '0',
        padding: '15px 0px 55px',
        overflowY: 'auto',
        height: '100vh',
        position: 'fixed',
        [left]: 0,
        zIndex: 998,
      };

      const renderView = ({ style, ...props }) => {
        const customStyle = {
          marginRight: 'auto',
          [rtl ? 'marginLeft' : 'marginRight']: '-17px',
        };
        return <div {...props} style={{ ...style, ...customStyle }} />;
      };

      const renderThumbVertical = ({ style, ...props }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
          [left]: '2px',
        };
        return <div style={{ ...style, ...thumbStyle }} {...props} />;
      };

      const renderTrackVertical = () => {
        const thumbStyle = {
          position: 'absolute',
          width: '6px',
          transition: 'opacity 200ms ease 0s',
          opacity: 0,
          [rtl ? 'left' : 'right']: '2px',
          bottom: '2px',
          top: '2px',
          borderRadius: '3px',
        };
        return <div style={thumbStyle} />;
      };

      const renderThumbHorizontal = ({ style, ...props }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
        };
        return <div style={{ ...style, ...thumbStyle }} {...props} />;
      };

      const style = !size
        ? {
          marginTop: 0,
        }
        : {};

      return (
        <Div darkMode={!darkMode}>
          <Layout className="layout">
            {size &&
              <Header
                style={{
                  position: 'fixed',
                  width: '100%',
                  top: 0,
                  [!rtl ? 'left' : 'right']: 0,
                }}
              >
                <Row>
                  <Col lg={!topMenu ? 4 : 3} sm={6} xs={12} className="align-center-v navbar-brand">
                    {!topMenu || window.innerWidth <= 991 ? (
                      <Button type="link" onClick={toggleCollapsed}>
                        <img src={require(`../static/img/icon/${collapsed ? 'right.svg' : 'left.svg'}`)} alt="menu" />
                      </Button>
                    ) : null}
                    <Link
                      className={topMenu && window.innerWidth > 991 ? 'striking-logo top-menu' : 'striking-logo'}
                      to="/admins"
                    >
                      <img style={{ width: '55px', height: '55px' }}
                        // style={{ width: '80px', height: '60px', marginTop: '0.5rem', marginLeft: '4rem' }}
                        src={
                          !darkMode
                            ? require(`../static/img/logo_de_full.svg`).default
                            : require(`../static/img/logo_de_full.svg`).default
                        }
                        alt=""
                      />
                    </Link>
                  </Col>

                  <Col lg={!topMenu ? 14 : 15} md={8} sm={0} xs={0}>
                    {/* {topMenu && window.innerWidth > 991 ? <TopMenu /> : <HeaderSearch rtl={rtl} darkMode={darkMode} />} */}
                  </Col>

                  <Col lg={6} md={10} sm={0} xs={0}>
                    {topMenu ? (
                      <TopMenuSearch>
                        <div className="top-right-wrap d-flex">
                          <Link
                            className={`${activeSearch ? 'search-toggle active' : 'search-toggle'}`}
                            onClick={() => {
                              toggleSearch();
                            }}
                            to="#"
                          >
                            <FeatherIcon icon="search" />
                            <FeatherIcon icon="x" />
                          </Link>
                          <div className={`${activeSearch ? 'topMenu-search-form show' : 'topMenu-search-form'}`}>
                            <form action="">
                              <span className="search-icon">
                                <FeatherIcon icon="search" />
                              </span>
                              <input type="text" name="search" />
                            </form>
                          </div>
                          <AuthInfo />
                        </div>
                      </TopMenuSearch>
                    ) : (
                      <AuthInfo />
                    )}
                  </Col>

                  <Col md={0} sm={18} xs={12}>
                    <div className="mobile-action">
                      {/* <Link className="btn-search" onClick={handleSearchHide} to="#">
                      {searchHide ? <FeatherIcon icon="search" /> : <FeatherIcon icon="x" />}
                    </Link> */}
                      <Link className="btn-auth" onClick={onShowHide} to="#">
                        <FeatherIcon icon="more-vertical" />
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Header>
            }
            {size &&
              <div className="header-more">
                <Row>
                  <Col md={0} sm={24} xs={24}>
                    <div className="small-screen-headerRight">
                      <SmallScreenSearch hide={searchHide} darkMode={darkMode}>
                        <HeaderSearch rtl={rtl} />
                      </SmallScreenSearch>
                      <SmallScreenAuthInfo hide={hide} darkMode={darkMode}>
                        <AuthInfo rtl={rtl} />
                      </SmallScreenAuthInfo>
                    </div>
                  </Col>
                </Row>
              </div>}
            <Layout>
              {/* {size &&
                <> */}
              {!topMenu ? (
                <ThemeProvider theme={darkTheme}>
                  <Sider width={280} style={SideBarStyle} collapsed={!collapsed} theme={!darkMode ? 'light' : 'dark'}>
                    <Scrollbars
                      className="custom-scrollbar"
                      autoHide
                      autoHideTimeout={500}
                      autoHideDuration={200}
                      renderThumbHorizontal={renderThumbHorizontal}
                      renderThumbVertical={renderThumbVertical}
                      renderView={renderView}
                      renderTrackVertical={renderTrackVertical}
                    >
                      {/* <p className="sidebar-nav-title">MAIN MENU</p> */}
                      <MenueItems
                        topMenu={topMenu}
                        rtl={rtl}
                        toggleCollapsed={toggleCollapsedMobile}
                        darkMode={darkMode}
                      />
                    </Scrollbars>
                  </Sider>
                </ThemeProvider>
              )
                : null}
              {/* </>
              } */}
              <Layout className="atbd-main-layout" style={style}>
                <Content>
                  <WrappedComponent {...this.props} />
                  {size &&
                    <Footer className="admin-footer" style={footerStyle}>
                      <Row>
                        <Col md={12} xs={24}>
                          {/* "http://www.onebitconsult.com/" */}
                          <a href="https://dineeazy.com/" className="admin-footer__link" target="_blank" rel="noopener noreferrer">
                            <span className="admin-footer__copyright">Powered by dineeazy @ 2024 </span>
                          </a>
                        </Col>
                      </Row>
                    </Footer>
                  }
                </Content>
              </Layout>
            </Layout>
          </Layout>
          <Link
            to="#"
            className="customizer-trigger"
            onClick={() => {
              screenSize();
            }}
          >
            <FeatherIcon icon={size ? "maximize" : "minimize"} />
          </Link>
          {showScrollDown && <Link
            to="#"
            className="customizer-triggerScroll" onClick={this.scrollDown}>
            <FeatherIcon icon="chevrons-down" />
          </Link>}
          {showScrollUp && <Link
            to="#"
            className="customizer-triggerScroll" onClick={this.scrollUp}>
            <FeatherIcon icon="chevrons-up" />
          </Link>}
        </Div >
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      ChangeLayoutMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  };

  LayoutComponent.propTypes = {
    ChangeLayoutMode: propTypes.bool,
    rtl: propTypes.bool,
    topMenu: propTypes.bool,
    changeRtl: propTypes.func,
    changeLayout: propTypes.func,
    changeMenuMode: propTypes.func,
  };

  return connect(mapStateToProps)(LayoutComponent);
};
export default ThemeLayout;
